import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Alert, AlertTitle, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@mui/material/Button";
import StopIcon from '@mui/icons-material/Stop';
import {useEffect, useState} from "react";
import {query,collection, onSnapshot, getDocs,orderBy,where,addDoc} from 'firebase/firestore'
import {db} from "../../Firebase";
import {UseAppointmentContext} from "../../Contexts/AppointmentContext";
import BookButton from "./Components/BookButton";
import AlertBox from "./Components/AlertBox";
import ConfirmDialog from "./Components/ConfirmDialog";
import {hover} from "@testing-library/user-event/dist/hover";
import getWorkLeaveList from '../../Helpers/GetWorkLeaveList';



const tomorrow = dayjs().add(1, 'day');


function AppointmentInformations(){
    // Get ConText Data
    const {
        date,
        setDate,
        doctor,
        setDoctorText,
        setDoctor,
        selectedSchedule,
        setSelectedSchedule,
        setScheduleError,
        scheduleError
    } = UseAppointmentContext()

    // ----------------------------------------------------------------------

    // Get Docs
    const [doctors, setDoctors] = useState([])
    async function getDoctors(){
        const q = query(collection(db,'doctors'))
        const docs = await getDocs(q)
        const doctors_list = []
        docs.docs.map((e,index)=>(
            doctors_list.push({...e.data(),id:e.id})
        ))
        setDoctors(doctors_list)
    }

    useEffect(()=>{
        getDoctors()
    },[])


    // ----------------------------------------------------------------------

    // Schedule
    const [schedules, setSchedules] = useState([])
    async function getScheduleIDOfDay(day_number,target_date,target_doctor){
        const q = query(collection(db,'scheduling'),
            where("weekday","==",day_number),
            where("doctorId","==",target_doctor)
            )
        const docs = await getDocs(q)
        var id = null
        docs.docs.map((e,index)=>(
            id = e.id

        ))

        const q_book = query(collection(db,'appointments'),
            where("timestamp", ">",target_date.startOf('day').unix()),
            where("timestamp", "<",target_date.endOf('day').unix())
        )
        const books = await getDocs(q_book)

        let booked_list = []
        books.docs.map((e,index)=>{
            if(e.data().doctorID== target_doctor && e.data().blockTime){
                booked_list.push(e.data().scheduleID)
            }
         }
        )


        const q_schedules = query(
            collection(db,'scheduling/'+id+'/times'),
            orderBy("order")
        )
        const docs_schedules = await getDocs(q_schedules)
        var list = []
        docs_schedules.docs.map((e,index)=>(
            list.push({...e.data(),id:e.id, booked:booked_list.includes(e.id)})
        ))
        setSchedules(list)
    }

    useEffect(()=>{
        if(date!=null){
            getScheduleIDOfDay(date.day(),date,doctor)
        }
    },[date,doctor])

    // Check Alert
    useEffect(()=>{
        setScheduleError(false)
    },[selectedSchedule])

    const[workLeaveList,setWorkLeaveList]=useState([])
    useEffect(()=>{
        getWorkLeaveList(setWorkLeaveList)
    },[])

    // ---------------------------------------------------------------------

    /*
    async function createSchedule(){

        const q_schedules = query(
            collection(db,'scheduling/BAWoY5abWxEgMkNZDeAR/times'),
            orderBy("order")
        )
        const docs_schedules = await getDocs(q_schedules)
        var list = []
        docs_schedules.docs.map((e,index)=>(
            list.push({...e.data()})
        ))
        const times = list

        for (let i = 1; i < 8; i++) {
            const doc = await addDoc(collection(db,'scheduling'),{
                weekday: i,
                doctorId:"FW2D4S3hQL9hpGaWxwgf"
            })

            for (let k = 0; k < times.length ; k++) {
                const time = times[k]
                await addDoc(collection(db,'scheduling/'+doc.id+'/times'),{
                    order: k+1,
                    startTime: time.startTime,
                    endTime:time.endTime,
                    active:true
                })
            }
        }
    }

    const [flag, setFlag] = useState(true)
    useEffect(()=>{
        if(flag){
            createSchedule()
            setFlag(false)
        }
    },[])

     */

    return(
        <>
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Randevu Bilgileri
                </Typography>

                <Grid container spacing={4}>

                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Psikolog</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Psikolog"
                                onChange={e => {
                                    setDoctor(e.target.value)
                                }}
                                value={doctor}
                            >
                                {
                                    doctors.map((doctor_info,index)=>(
                                        <MenuItem key={index}  value={doctor_info.id}>{doctor_info.name}  {doctor_info.surname}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText="tr" adapterLocale={"tr"}>
                                    <DatePicker
                                        disabled={doctor!=null?false:true}
                                        label="Randevu Tarihi"
                                        minDate={dayjs()}
                                        value={date}
                                        onChange={(newValue) => setDate(dayjs(newValue))}
                                        views={['year', 'month', 'day']}
                                    />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item className={"flex"} xs={12} md={12}>
                        <p>Randevu Saatleri |</p>
                        <StopIcon color="primary" ></StopIcon>
                        <p> Seçilebilir Saatler |</p>
                        <StopIcon color="success" ></StopIcon>
                        <p> Seçilmiş Olan Saat</p>


                    </Grid>
                    <Grid item xs={12} md={12}>
                        <hr/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AlertBox type="warning" body="Uygun Saat dilimlerini görmek için öncelikle doktor seçimi, ardından gün seçimi yapmalısı bvvc  nız!" visible={schedules.length==0?true:false} ></AlertBox>
                    </Grid>

                    {
                        schedules.map(function (schedule){
                            var disable = false
                            if(!schedule.active){
                                disable = true
                            }
                            else if(schedule.booked){
                                disable = true
                            }
                            else if(date.format('DD/MM/YYYY') == dayjs().format('DD/MM/YYYY')){
                                var now = dayjs().format("H:m")
                                var a = now.split(':'); // split it at the colons
                                // minutes are worth 60 seconds. Hours are worth 60 minutes.
                                var nowSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;

                                var a = schedule.startTime.split(':'); // split it at the colons
                                var scheduleSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
                                if(scheduleSeconds<nowSeconds){
                                    disable = true
                                }
                            }
                            var scheduleStartTime = date.hour(schedule.startTime.split(':')[0]).minute(schedule.startTime.split(':')[1]).unix()
                                var scheduleEndTime = date.hour(schedule.endTime.split(':')[0]).minute(schedule.endTime.split(':')[1]).unix()
                                console.log("start",scheduleStartTime)
                                workLeaveList.map((leave)=>{
                                    console.log(leave.start)
                                        if(leave.start<=scheduleStartTime&&leave.finish>scheduleStartTime){
                                            disable = true
                                        }
                                })
                            return(
                                <Grid item xs={6} md={3} >
                                    <BookButton id={schedule.id} disable={disable} text={schedule.startTime+"-"+schedule.endTime}></BookButton>
                                </Grid>
                            )
                        })
                    }

                    <Grid item xs={12}>
                        <AlertBox type="warning" body="Lütfen geçerli bir randevu tarihi seçiniz!" visible={scheduleError} ></AlertBox>
                    </Grid>
                </Grid>
            </React.Fragment>
        </>
    )
}
export default AppointmentInformations