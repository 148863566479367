import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../Firebase";

async function getPsychologists(setFunction) {
    const q = query(
        collection(db, 'doctors')
    )
    const docs = await getDocs(q)

    var list = []
    docs.docs.map(async function (doc) {
            list.push({...doc.data(),id:doc.id})
    })
    setFunction(list)
}
export default getPsychologists